import styled from '@emotion/styled'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Nav } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ReactJoyride, { CallBackProps, STATUS } from 'react-joyride'
import Title from '../../components/global/Title'
import Planning from '../../components/planning/Planning'
import Prices from '../../components/planning/Prices'

const HEADER_HEIGHT = 107
const NAVBAR_HEIGHT = 50
const SCROLL_MARGIN_TOP = 16

const scrollHeightDefault = HEADER_HEIGHT + NAVBAR_HEIGHT + SCROLL_MARGIN_TOP

const PlanningPage = () => {
    const [holidayName, setHolidayName] = useState('')
    const [mainName, setMainName] = useState('')
    const [holidaysName, setHolidaysName] = useState('')
    const mainPlanningRef = useRef<null | HTMLDivElement>(null)
    const summerPlanningRef = useRef<null | HTMLDivElement>(null)
    const holidaysPlanningRef = useRef<null | HTMLDivElement>(null)
    const tryTextRef = useRef<null | HTMLDivElement>(null)
    const tarifTextRef = useRef<null | HTMLDivElement>(null)

    const { t } = useTranslation('translation')

    const helpUsed = useMemo(() => !!localStorage.getItem('helpUsed'), [localStorage.getItem('helpUsed')])

    const [joyRideState, setJoyRideState] = useState<ReactJoyride['props']>({
        run: !helpUsed,
        steps: [
            {
                disableBeacon: true,
                target: 'body',
                title: t('page.planning.joyride.body.title'),
                content: t('page.planning.joyride.body.content'),
                placement: 'center',
                showProgress: true,
                showSkipButton: true,
                hideCloseButton: true,
            },
            {
                disableBeacon: true,
                target: '#help-btn',
                title: t('page.planning.joyride.help-btn.title'),
                content: t('page.planning.joyride.help-btn.content'),
                placement: 'auto',
                showProgress: true,
                showSkipButton: true,
                disableScrolling: true,
            },
        ],
    })

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status } = data
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]

        if (finishedStatuses.includes(status)) {
            setJoyRideState({ ...joyRideState, run: false })
            localStorage.setItem('helpUsed', '1')
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }

    const [navBarSelected, setNavBarSelected] = useState('')

    useEffect(() => {
        if (navBarSelected === '') {
            setNavBarSelected(holidayName ?? mainName)
        }
    }, [holidayName, mainName])

    return (
        <Container>
            <ReactJoyride
                steps={joyRideState.steps.map((step) => step).filter((step) => !!step)}
                run={joyRideState.run}
                callback={handleJoyrideCallback}
                scrollToFirstStep
                showProgress
                showSkipButton
                continuous
                locale={{
                    next: t('joyride.next'),
                    last: t('joyride.last'),
                    skip: t('joyride.skip'),
                    back: t('joyride.back'),
                    close: t('joyride.close'),
                }}
                hideCloseButton
                styles={{
                    buttonNext: { backgroundColor: 'var(--primary-color)' },
                    buttonBack: { color: 'var(--primary-color)' },
                    tooltipTitle: { color: 'var(--primary-color)', fontSize: '2.5rem' },
                    buttonSkip: { color: 'white', backgroundColor: 'black', borderRadius: '4px' },
                }}
            />
            {window.innerWidth > 904 && (
                <NavBar>
                    {holidayName && (
                        <Nav.Item>
                            <Nav.Link
                                active={navBarSelected === holidayName}
                                onClick={() => {
                                    window.scrollTo({
                                        behavior: 'smooth',
                                        top: (summerPlanningRef.current?.offsetTop as number) - scrollHeightDefault,
                                    })
                                    setNavBarSelected(holidayName)
                                }}
                            >
                                {holidayName}
                            </Nav.Link>
                        </Nav.Item>
                    )}
                    {mainName && (
                        <Nav.Item>
                            <Nav.Link
                                active={navBarSelected === mainName}
                                onClick={() => {
                                    window.scrollTo({
                                        behavior: 'smooth',
                                        top: (mainPlanningRef.current?.offsetTop as number) - scrollHeightDefault,
                                    })
                                    setNavBarSelected(mainName)
                                }}
                            >
                                {mainName}
                            </Nav.Link>
                        </Nav.Item>
                    )}
                    <Nav.Item>
                        <Nav.Link
                            active={navBarSelected === t('page.price.title-try')}
                            onClick={() => {
                                window.scrollTo({
                                    behavior: 'smooth',
                                    top: (tryTextRef.current?.offsetTop as number) - scrollHeightDefault,
                                })
                                setNavBarSelected(t('page.price.title-try'))
                            }}
                        >
                            {t('page.price.title-try')}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                            active={navBarSelected === t('page.price.title')}
                            onClick={() => {
                                window.scrollTo({
                                    behavior: 'smooth',
                                    top: (tarifTextRef.current?.offsetTop as number) - scrollHeightDefault,
                                })
                                setNavBarSelected(t('page.price.title'))
                            }}
                        >
                            {t('page.price.title')}
                        </Nav.Link>
                    </Nav.Item>
                </NavBar>
            )}

            <Title
                style={{
                    position: 'relative',
                    textAlign: 'center',
                }}
            >
                {t('page.price.warning-off-day')}
                <HelpButton id="help-btn" type="button" onClick={() => setJoyRideState({ ...joyRideState, run: true })}>
                    {t('joyride.help')}
                </HelpButton>
            </Title>

            <Planning
                summerPlanningRef={summerPlanningRef}
                summerName={holidayName}
                setSummerName={setHolidayName}
                holidaysPlanningRef={holidaysPlanningRef}
                holidaysName={holidaysName}
                setHolidaysName={setHolidaysName}
                mainPlanningRef={mainPlanningRef}
                mainName={mainName}
                setMainName={setMainName}
                joyRideState={joyRideState}
                setJoyRideState={setJoyRideState}
            />
            <Prices
                tryTextRef={tryTextRef}
                tarifTextRef={tarifTextRef}
                joyRideState={joyRideState}
                setJoyRideState={setJoyRideState}
            />
        </Container>
    )
}

const HelpButton = styled.button`
    position: absolute;
    top: 10px;
    right: 3%;
    background-color: var(--primary-color);
    border: 0px;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 2.6rem;
    line-height: 1;
    padding: 8px;
    appearance: none;
    transition: all 0.4s;

    &:hover {
        opacity: 0.7;
    }
`

const NavBar = styled(Nav)`
    position: fixed;
    width: 100vw;
    display: flex;
    padding-left: 3%;
    z-index: 4;
    left: 0;
    top: 108px;
    background: white;
    box-shadow: 0 0 3px;
    height: ${NAVBAR_HEIGHT}px;

    & .nav-item {
        margin-right: 30px;
    }

    & .nav-link {
        background: none !important;
        padding: 8px 0;
        text-decoration: none;
        color: black;
        font-size: 2rem;
        font-family: 'Roboto' !important;

        &:hover {
            color: var(--primary-color);
        }

        &.active {
            color: var(--primary-color);
            border-bottom: 5px solid var(--primary-color);
        }

        &:active {
            transform: scale(0.95);
        }
    }
`

const Container = styled.div`
    position: relative;
    padding: 58px 3% 24px 3%;
    overflow: auto hidden;
`

export default PlanningPage
