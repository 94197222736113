import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'
import { MutableRefObject, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactJoyride from 'react-joyride'
import { useLocation } from 'react-router-dom'
import Breakpoint from '../../Breakpoint'
import { getAllPrices } from '../../api/price.api'
import { useLangSelected } from '../../hooks/useLangSelected'
import { PriceModel } from '../../types/price.type'
import Loader from '../global/Loader'
import PriceCard from '../global/PriceCard'
import Title from '../global/Title'
import { PlanningSubTitle } from './Planning'

type Props = {
    tryTextRef: MutableRefObject<HTMLDivElement | null>
    tarifTextRef: MutableRefObject<HTMLDivElement | null>
    joyRideState: ReactJoyride['props']
    setJoyRideState: (v: ReactJoyride['props']) => void
}

const Prices = ({ tryTextRef, tarifTextRef, joyRideState, setJoyRideState }: Props) => {
    const location = useLocation()

    const lng = useLangSelected()

    const { t } = useTranslation('translation')

    useEffect(() => {
        const hash = location.hash
        const el = hash && document.getElementById(hash.substring(1))

        if (el) {
            setTimeout(() => el.scrollIntoView({ behavior: 'smooth' }), 1000)
        }
    }, [])

    const { data: prices } = useQuery({
        queryKey: ['price-cards'],
        queryFn: async () => {
            const prices = await getAllPrices(lng)
            joyRideState.steps[6] = {
                disableBeacon: true,
                target: '#try',
                title: t('page.planning.joyride.try.title'),
                content: t('page.planning.joyride.try.content'),
                placement: 'auto',
                showProgress: true,
                showSkipButton: true,
                hideCloseButton: true,
            }

            joyRideState.steps[7] = {
                disableBeacon: true,
                target: '#tarif',
                title: t('page.planning.joyride.our-prices.title'),
                content: t('page.planning.joyride.our-prices.content'),
                placement: 'auto',
                showProgress: true,
                showSkipButton: true,
                hideCloseButton: true,
            }

            setJoyRideState({
                ...joyRideState,
            })

            return prices
        },
    })

    if (!prices) return <Loader />

    return (
        <div>
            <div ref={tryTextRef} id="try">
                <Title>{t('page.price.title-try')}</Title>
                <PriceContainer>
                    <p>
                        {t('page.price.price-try-1')} <br />
                        {t('page.price.price-try-2')} <br />
                        {t('page.price.price-try-3')} <br />
                    </p>
                </PriceContainer>
            </div>

            <div ref={tarifTextRef} id="tarif">
                <Title>{t('page.price.title')}</Title>
                <PlanningSubTitle>
                    <p>{t('page.price.register-info-date')}</p>
                    <p>{t('page.price.register-info-competition')}</p>
                </PlanningSubTitle>

                {prices.length > 0 && (
                    <PriceCardWrapper>
                        {prices.map((price: PriceModel) => (
                            <PriceCard
                                key={price.id}
                                title={price.name}
                                price={price.price}
                                midSeasonPrice={price.midSeasonPrice}
                                reductions={price.reductions}
                                description={price.description}
                            />
                        ))}
                    </PriceCardWrapper>
                )}
            </div>
        </div>
    )
}

const PriceCardWrapper = styled.div`
    display: grid;
    align-content: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    @media (max-width: ${Breakpoint.LAPTOP_XS}) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: ${Breakpoint.TABLET_XS}) {
        display: flex;
        flex-direction: column;
    }
`

const PriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
    padding: 16px;
    border-radius: 6px;
    margin-bottom: 16px;
    box-sizing: border-box;
    font-size: 2rem;
`

export default Prices
