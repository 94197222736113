import styled from '@emotion/styled'
import { faCircle as faCircleRegular } from '@fortawesome/free-regular-svg-icons'
import { faCircle, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import axios from 'axios'
import { MutableRefObject, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactJoyride from 'react-joyride'
import Breakpoint from '../../Breakpoint'
import { SERVER_URL, apiHeader } from '../../config'
import UserContext from '../../context/UserContext'
import { useLangSelected } from '../../hooks/useLangSelected'
import { PlanningData } from '../../types/planning.type'
import catchError from '../global/CatchError'
import Loader from '../global/Loader'
import Section from '../global/Section'
import Title from '../global/Title'
import PlanningContent from './PlanningContent'
import PlanningHeader from './PlanningHeader'

type Props = {
    summerPlanningRef: MutableRefObject<HTMLDivElement | null>
    summerName: string
    setSummerName: (v: string) => void
    holidaysPlanningRef: MutableRefObject<HTMLDivElement | null>
    holidaysName: string
    setHolidaysName: (v: string) => void
    mainPlanningRef: MutableRefObject<HTMLDivElement | null>
    mainName: string
    setMainName: (v: string) => void
    joyRideState: ReactJoyride['props']
    setJoyRideState: (v: ReactJoyride['props']) => void
}

const Planning = ({
    summerPlanningRef,
    summerName,
    setSummerName,
    mainPlanningRef,
    mainName,
    setMainName,
    holidaysPlanningRef,
    holidaysName,
    setHolidaysName,
    joyRideState,
    setJoyRideState,
}: Props) => {
    const user = useContext(UserContext)

    const lng = useLangSelected()

    const { t } = useTranslation('translation')

    const [mainPlanningData, setMainPlanningData] = useState<PlanningData | undefined>(undefined)
    const [mainLoading, setMainLoading] = useState(true)

    const [summerPlanningData, setSummerPlanningData] = useState<PlanningData | undefined>(undefined)
    const [summerLoading, setSummerLoading] = useState(true)

    const [holidaysPlanningData, setHolidaysPlanningData] = useState<PlanningData | undefined>(undefined)
    const [holidaysLoading, setHolidaysLoading] = useState(true)

    const loadPlannings = () => {
        axios
            .post(`${SERVER_URL}/plannings/page/horaire`, { lng }, apiHeader())
            .then(({ data }) => {
                if (data) {
                    setMainName(data.name)
                    setMainPlanningData({
                        headerTime: data.headerTime,
                        monday: data.monday,
                        tuesday: data.tuesday,
                        wednesday: data.wednesday,
                        thursday: data.thursday,
                        friday: data.friday,
                        saturday: data.saturday,
                        sunday: data.sunday,
                    })

                    joyRideState.steps[4] = {
                        disableBeacon: true,
                        target: '#planning',
                        title: t('page.planning.joyride.planning.title'),
                        content: t('page.planning.joyride.planning.content'),
                        placement: 'auto',
                        showProgress: true,
                        showSkipButton: true,
                    }

                    joyRideState.steps[5] = {
                        disableBeacon: true,
                        target: '#legend',
                        title: t('page.planning.joyride.legend.title'),
                        content: t('page.planning.joyride.legend.content'),
                        placement: 'auto',
                        showProgress: true,
                        showSkipButton: true,
                        disableScrolling: true,
                    }

                    setJoyRideState({
                        ...joyRideState,
                    })
                }
                setMainLoading(false)
            })
            .catch((err) => catchError(err.response.data))

        axios
            .post(`${SERVER_URL}/plannings/page/ete`, { lng }, apiHeader(user.tokenAdmin))
            .then(({ data }) => {
                if (data) {
                    setSummerName(data.name)
                    setSummerPlanningData({
                        headerTime: data.headerTime,
                        monday: data.monday,
                        tuesday: data.tuesday,
                        wednesday: data.wednesday,
                        thursday: data.thursday,
                        friday: data.friday,
                        saturday: data.saturday,
                        sunday: data.sunday,
                    })

                    joyRideState.steps[3] = {
                        disableBeacon: true,
                        target: '#planning-summer',
                        title: t('page.planning.joyride.planning-summer.title'),
                        content: t('page.planning.joyride.planning-summer.content'),
                        placement: 'auto',
                        showProgress: true,
                        showSkipButton: true,
                    }

                    setJoyRideState({
                        ...joyRideState,
                    })
                }
                setSummerLoading(false)
            })
            .catch((err) => catchError(err.response.data))

        axios
            .post(`${SERVER_URL}/plannings/page/holidays`, { lng }, apiHeader())
            .then(({ data }) => {
                if (data) {
                    setHolidaysName(data.name)
                    setHolidaysPlanningData({
                        headerTime: data.headerTime,
                        monday: data.monday,
                        tuesday: data.tuesday,
                        wednesday: data.wednesday,
                        thursday: data.thursday,
                        friday: data.friday,
                        saturday: data.saturday,
                        sunday: data.sunday,
                    })

                    joyRideState.steps[2] = {
                        disableBeacon: true,
                        target: '#planning-holidays',
                        title: t('page.planning.joyride.planning-holidays.title'),
                        content: t('page.planning.joyride.planning-holidays.content'),
                        placement: 'auto',
                        showProgress: true,
                        showSkipButton: true,
                    }

                    setJoyRideState({
                        ...joyRideState,
                    })
                }
                setHolidaysLoading(false)
            })
            .catch((err) => catchError(err.response.data))
    }

    useEffect(() => {
        loadPlannings()
    }, [user.tokenAdmin, lng])

    if (mainLoading && summerLoading && holidaysLoading) return <Loader />

    return (
        <>
            {holidaysPlanningData && (
                <div id="planning-holidays">
                    <Header ref={holidaysPlanningRef}>
                        <div>
                            <Title>{holidaysName}</Title>
                        </div>

                        <Legend>
                            <TitleLegend>Légende</TitleLegend>

                            <div>
                                <PrimaryIcon icon={faCircle} /> Salle du blason{' '}
                                <a
                                    href="https://www.google.com/maps/place/Salle+Blason/@50.6253722,3.1212522,17z/data=!3m1!4b1!4m5!3m4!1s0x47c2d63d6dc9e9af:0x370d617a274544fc!8m2!3d50.6253745!4d3.1234539"
                                    target="_blank"
                                >
                                    <DotPosition icon={faLocationDot} />
                                </a>
                            </div>
                            <div>
                                <WhiteIcon icon={faCircleRegular} color="white" /> Complexe des ESUM{' '}
                                <a
                                    href="https://www.google.com/maps/place/Espace+Sportif+Universitaire+Municipale/@50.6297379,3.1216652,17z/data=!3m1!4b1!4m5!3m4!1s0x47c2d62289a8882b:0x17d9586a355b951e!8m2!3d50.6298932!4d3.1236674"
                                    target="_blank"
                                >
                                    <DotPosition icon={faLocationDot} />
                                </a>
                            </div>
                        </Legend>
                    </Header>

                    <PlanningContainer>
                        <PlanningHeader planning={holidaysPlanningData} />
                        <PlanningContent planning={holidaysPlanningData} />
                    </PlanningContainer>
                </div>
            )}

            {summerPlanningData && (
                <div id="planning-summer">
                    <Header ref={summerPlanningRef}>
                        <div>
                            <Title>{summerName}</Title>
                            <PlanningSubTitle>
                                {t('page.planning.summer-sessions')} <br />
                            </PlanningSubTitle>
                        </div>
                        <Legend>
                            <TitleLegend>Légende</TitleLegend>

                            <div>
                                <PrimaryIcon icon={faCircle} /> Salle du blason{' '}
                                <a
                                    href="https://www.google.com/maps/place/Salle+Blason/@50.6253722,3.1212522,17z/data=!3m1!4b1!4m5!3m4!1s0x47c2d63d6dc9e9af:0x370d617a274544fc!8m2!3d50.6253745!4d3.1234539"
                                    target="_blank"
                                >
                                    <DotPosition icon={faLocationDot} />
                                </a>
                            </div>
                            <div>
                                <WhiteIcon icon={faCircleRegular} color="white" /> Complexe des ESUM{' '}
                                <a
                                    href="https://www.google.com/maps/place/Espace+Sportif+Universitaire+Municipale/@50.6297379,3.1216652,17z/data=!3m1!4b1!4m5!3m4!1s0x47c2d62289a8882b:0x17d9586a355b951e!8m2!3d50.6298932!4d3.1236674"
                                    target="_blank"
                                >
                                    <DotPosition icon={faLocationDot} />
                                </a>
                            </div>
                        </Legend>
                    </Header>

                    <PlanningContainer>
                        <PlanningHeader planning={summerPlanningData} />
                        <PlanningContent planning={summerPlanningData} />
                    </PlanningContainer>
                </div>
            )}

            {mainPlanningData && (
                <div id="planning">
                    <Header ref={mainPlanningRef}>
                        <Title id={mainName}>{mainName}</Title>
                        <Legend id="legend">
                            <TitleLegend>Légende</TitleLegend>

                            <div>
                                <PrimaryIcon icon={faCircle} /> Salle du blason{' '}
                                <a href="https://maps.app.goo.gl/ZcyNqJhmb95ZoDg98" target="_blank">
                                    <DotPosition icon={faLocationDot} />
                                </a>
                            </div>
                            <div>
                                <WhiteIcon icon={faCircleRegular} color="white" /> Complexe des ESUM{' '}
                                <a href="https://maps.app.goo.gl/ZfZQaSa8FfptD1e78" target="_blank">
                                    <DotPosition icon={faLocationDot} />
                                </a>
                            </div>

                            <CompetitorLegendText
                                style={{
                                    marginTop: '16px',
                                }}
                            >
                                🏆 Séance Compétiteur{' '}
                            </CompetitorLegendText>
                        </Legend>
                    </Header>

                    <PlanningContainer>
                        <PlanningHeader planning={mainPlanningData} />
                        <PlanningContent planning={mainPlanningData} />
                    </PlanningContainer>
                </div>
            )}
        </>
    )
}

const CompetitorLegendText = styled.div`
    margin-top: 16px;
`

const TitleLegend = styled.div`
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 8px;
`

const DotPosition = styled(FontAwesomeIcon)`
    height: 25px;
`

export const PlanningSubTitle = styled.div`
    margin-bottom: 16px;
    font-size: 1.8rem;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${Breakpoint.LAPTOP_XS}) {
        flex-direction: column;
    }
`

const PrimaryIcon = styled(FontAwesomeIcon)`
    color: var(--primary-color);
`

const WhiteIcon = styled(FontAwesomeIcon)`
    color: var(--primary-color);
`

const Legend = styled(Section)`
    display: flex;
    flex-direction: column;
    width: max-content;
    margin-bottom: 16px;

    @media (max-width: ${Breakpoint.LAPTOP_XS}) {
        width: 100%;
    }
`

const PlanningContainer = styled.div`
    width: 1800px;
    background-color: white;
    border-radius: 8px;
    margin-bottom: 32px;
`

export default Planning
