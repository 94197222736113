const ptTranslation = () => {
    return {
        global: {
            monday: 'Segunda-feira',
            tuesday: 'Terça-feira',
            wednesday: 'Quarta-feira',
            thursday: 'Quinta-feira',
            friday: 'Sexta-feira',
            saturday: 'Sábado',
            sunday: 'Domingo',
            lang: {
                fr: 'Francês (French)',
                gb: 'Inglês (English)',
                br: 'Brasil (Brazil)',
            },
        },
        home: {
            'main-title': "Badminton Villeneuve d'Ascq",
            'presentation-1': 'Jovens e Adultos, competidores e lazer, ',
            'presentation-2': ' ',
            'presentation-3': '',
            'presentation-4': ' O BVA promove a diversão para todos os jogadores de badminton.',
            'last-news': 'Últimas notícias',
        },
        header: {
            menu: {
                home: 'Início',
                planning: 'Planos e Preços',
                registration: 'Inscrever-se',
                competition: 'Torneios',
                contact: 'Fale conosco',
                'my-space-bva': 'Meu espaço BVA',
                login: 'Entre',
            },
            'my-tournament-account': 'Minha conta de torneios',
            'tournament-account-message':
                'A conta de torneios começa em 0€ e pode alcançar o máximo de 25€.Ela diz respeito somente aos competidores. É fácil para aumentar os valores na sua conta: basta participar dos treinamentos e nos avisar se estiver ausente.',
        },
        footer: {
            copyright: 'Copyright © 2022. Todos os direitos reservados - Site criado por',
            administration: 'Administrador',
        },
        sidebar: {
            admin: {
                dashboard: 'Painel',
                user: 'Usuário',
                news: 'Notícias',
                competition: 'Torneios',
                plannings: 'Planos',
                prices: 'Taxas',
                documentation: 'Documentação',
                'bva-bot': 'BVA bot',
                'to-document-myself': 'Para me documentar',
                password: 'Senha',
            },
        },
        admin: {
            dashboard: 'Painel',
            or: 'ou',
            'search-member-input': '🔍 Pesquisar um membro',
        },
        member: {
            dashboard: 'Painel',
            documentation: 'Documentação',
            'search-document-input': '🔍 Pesquisar um documento',
            'welcome-1': 'Oi {{ user.firstname }} {{ user.lastname }},',
            'welcome-2': 'Bem-vindo à sua conta BVA',
            'welcome-3': 'Aqui é onde a sua conta de torneios é exibida (útil para a Licença de Competidor).',
            'welcome-4': 'No futuro, novas ferramentas serão adicionadas',
            'welcome-5':
                'Se você tem qualquer sugestão para melhoria do site nos contacte por email: president@bva-club.fr.',
            'last-news': 'Últimas notícias',
            'change-password-title': 'Mudar senha',
            'current-password-label': 'Senha atual',
            'new-password-label': 'Nova senha',
            'confirm-password-label': 'Confirme nova senha',
        },
        error: {
            'session-expired': 'Sua sessão expirou, você está sendo desconectado por motivos de segurança.',
        },
        page: {
            planning: {
                joyride: {
                    body: {
                        title: 'Horários e Preços',
                        content: 'Um pequeno tour guiado para ajudá-lo a encontrar seu caminho e não perder nada!',
                    },
                    'help-btn': {
                        title: 'Botão Guie-me',
                        content: 'Este botão reinicia o tour guiado.',
                    },
                    'planning-holidays': {
                        title: 'Calendário de Férias Escolares',
                        content: 'Este calendário corresponde às sessões disponíveis durante as férias escolares.',
                    },
                    'planning-summer': {
                        title: 'Calendário de Férias de Verão',
                        content: 'Este calendário corresponde às sessões disponíveis durante as férias de verão.',
                    },
                    legend: {
                        title: 'A Legenda',
                        content: 'Cada calendário contém uma legenda que o ajudará a entender melhor o calendário.',
                    },
                    planning: {
                        title: 'Calendário Principal',
                        content: 'Este calendário corresponde às sessões principais que ocorrerão durante todo o ano.',
                    },
                    try: {
                        title: 'Sessões de julgamento',
                        content: 'Se você for um novato, isso deve lhe interessar!',
                    },
                    'our-prices': {
                        title: 'Nossas licenças',
                        content:
                            'Depois de ter feito pelo menos uma sessão de teste, você pode nos solicitar o formulário de registro. Nesse formulário, você pode escolher a licença que mais lhe convém e o método de pagamento de sua preferência.',
                    },
                },
                'summer-sessions':
                    'As sessões de verão são abertas a todos durante as férias de verão. É um bom momento para vir e experimentar e se divertir conosco nas quadras.',
            },
            contact: {
                title: 'Fale conosco',
            },
            competition: {
                title: 'Torneios',
                'badnet-register': 'Se inscreva no BadNet',
                'tournament-account-register': 'Se inscreva com a sua conta de torneios',
                'need-login': 'Para usar a sua conta de torneios, você precisa se conectar',
                'no-data': 'Nenhum torneio ainda. Nós vamos adicioná-los em breve.',
            },
            price: {
                title: 'Preços para a temporada 2024/2025',
                'title-try': 'Sessões Experimentais',
                'price-try-1': 'Antes de se registrar, você pode usufruir de 3 sessões experimentais.',
                'price-try-2': 'Nós podemos emprestar raquetes e petecas para as sessões experimentais.',
                'price-try-3': 'Após isso, você deve trazer sua própria raquete e petecas.',
                'register-info-date':
                    'As inscrições serão abertas em 1º de agosto para renovações de membros existentes. E a partir de 2 de setembro de 2024 para novos membros.',
                'register-info-competition': 'Todas as nossas licenças permitem que você participe de competições.',
                'warning-off-day': '⚠️ Observe: O clube está sempre fechado nos feriados ! ⚠️',
            },
            login: {
                connexion: 'Login',
                member: 'membro',
                admin: 'administrador',
            },
        },
        joyride: {
            help: 'Me guie !',
            next: 'Próximo',
            last: 'Finalizar Tour',
            skip: 'Fechar',
            back: 'Anterior',
            close: 'Fechar',
        },
        bot: {
            init: 'Oi, como posso te ajudar? Faça uma pergunta curta e use palavras-chave. Por examplo, a palavra "preço" irá te redirecionar para a página com informações sobre valores. Para mensagens longas, nos contacte através do email : contact@bva-club.fr',
        },
        cookies: 'Este site utiliza cookies que dura apenas 24h para medir o número de visitas.',
    }
}

export default ptTranslation
